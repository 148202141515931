<template>
  <div class="testTracking">
    <NavBar :back="true">人脸采集</NavBar>
    <div class="faceTips column-end-center">
      <div class="tips1">请勿在逆光或暗光环境下拍摄</div>
<!--      <div class="tips1">若授权使用摄像头后任无法显示画面</div>-->
<!--      <div class="tips1">请返回上级再试</div>-->
      <div class="tips2">{{ tips }}</div>
    </div>
    <div class="readFace">
      <img class="faceQG" src="../assets/face.png">
      <div class="timeTips center">{{ timeTips }}</div>
      <video ref="faceVideo" id="faceVideo" :width="winWidth" :height="winWidth" preload="auto" playsinline autoplay
             loop
             muted></video>
      <canvas ref="faceCanvas" id="faceCanvas" :width="winWidth" :height="winWidth"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Face',
  data() {
    return {
      params: this.$route.params,
      timeTips: "",
      winWidth: this.$store.state.remNum * 16,
      winHeight: this.$store.state.winHeight,
      haveFace: false,
      readyTakeFace: false,
      lock: true

    }
  },
  created() {
    if (this.faceInterval) {
      clearInterval(this.faceInterval)
    }
  },
  beforeDestroy() {
    this.trackerStop()
  },
  computed: {
    tips() {
      return this.haveFace ? "正在识别" : "请将摄像头正对脸部"
    }
  },
  mounted() {
    this.context = this.$refs.faceCanvas.getContext('2d');
    // eslint-disable-next-line no-undef
    let tracker = new tracking.ObjectTracker("face");
    tracker.setInitialScale(4);
    tracker.setStepSize(2);
    tracker.setEdgesDensity(0.1);

    // eslint-disable-next-line no-undef
    this.trackerTask = tracking.track('#faceVideo', tracker, {camera: true});
    this.trackerOn(tracker);
  },
  methods: {
    trackerOn(tracker) {
      let that = this;
      // let dataURLtoBlob = function (b64Image) {
      //   let img = atob(b64Image.split(',')[1]);
      //   let img_buffer = [];
      //   let i = 0;
      //   while (i < img.length) {
      //     img_buffer.push(img.charCodeAt(i));
      //     i++;
      //   }
      //   return new Uint8Array(img_buffer);
      // };
      tracker.on('track', function (event) {
        event.data.forEach(function () {
          if (!that.haveFace) {
            that.haveFace = true;
            that.createFaceInterval()
          } else {
            if (that.readyTakeFace) {
              if (that.lock) {
                that.lock = false;
                that.context.drawImage(that.$refs.faceVideo, 0, 0, that.winWidth, that.winWidth);
                let img64 = that.$refs.faceCanvas.toDataURL("image/jpeg")
                let sta = img64.indexOf('base64')
                that.$toast.loading('正在校验人脸')
                that.trackerStop()
                that.api.upLoadFace({
                  sign:that.$store.state.sign.sign,
                  orderNum: that.$store.state.sign.orderNum,
                  idcard: that.$store.state.visitedPlayer.userIdcard,
                  data: img64.slice(sta + 7)
                }).then(apiRes => {
                  if (apiRes.success) {
                    that.api.getOrderByCode().then(aaRes => {
                      that.$toast.success('人脸采集成功！请刷脸入园')
                      if (aaRes.success) {
                        that.$store.commit('setOrderData', aaRes.data)
                        that.$router.go(-1)
                      }
                    })
                  } else {
                    that.$toast.fail(apiRes.msg);
                    setTimeout(() => {
                      that.resetFace()
                    }, 1000)
                  }
                })
                // console.log("1-base64:",img64)
                // let imgData = dataURLtoBlob(img64);
                //  console.log("2-bufferList:",imgData)
                // let formData = new FormData();
                // let config = {type: "image/jpg"};
                // formData.append("file", new Blob([imgData]));
                // formData.append("orderNum", that.$store.state.sign.orderNum);
                // formData.append("idcard", that.$store.state.visitedPlayer.userIdcard);
                // axios.post(API_URL + "uploadFace.do", formData)
                //     .then(res => {
                //       if (res.data.code == 200) {
                //         that.trackerStop();
                //         that.$toast.success('上传成功！');
                //         that.$router.back(1)
                //       } else {
                //         that.$toast.fail(res.data.msg);
                //         setTimeout(() => {
                //           that.resetFace()
                //         }, 1000)
                //       }
                //     })
                // .catch(() => {
                //     console.log("失败")
                //     that.$toast.fail("识别失败，请重试");
                //     setTimeout(() => {
                //         that.resetFace()
                //     }, 1000)
                // })
              }
            }
          }
        });
      });
    },
    createFaceInterval() {
      let timeTips = 3;
      this.timeTips = timeTips;
      this.faceInterval = setInterval(() => {
        this.haveFace = true;
        if (timeTips > 1) {
          timeTips--;
          this.timeTips = timeTips;
        } else {
          clearInterval(this.faceInterval);
          this.timeTips = ""
          this.readyTakeFace = true
        }
      }, 1000)
    },
    resetFace() {
      this.context.clearRect(0, 0, this.winWidth, this.winWidth);
      clearInterval(this.faceInterval);
      // canvas.c
      this.timeTips = "";
      this.haveFace = false;
      this.readyTakeFace = false;
      this.lock = true;
    },
    trackerStop() {
      // 停止侦测
      this.trackerTask.stop()
      // navigator.mediaDevices&& navigator.mediaDevices.stop()
      // 关闭摄像头
      if (typeof window.stream === "object") {
        //	this.videoEl是视频流容器，也就是video标签，需要在data中声明这个变量，然后在打开摄像头的方法中this.videoEl.srcObject = window.stream
        this.$refs.faceVideo.srcObject = null;
        window.stream.getTracks().forEach(track => track.stop());
      }
    }
  },
}

</script>

<style scoped>
.testTracking {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*width: 100vw;*/
  /*height: 100vh;*/
  background-color: #00b453;
}

.faceTips {
  margin-top: 1rem;
  height: 4rem;
  color: #F2F2F2;
  font-weight: bold;
}

.tips1 {
  font-size: .5rem;
}

.tips2 {
  padding-top: .6rem;
  font-size: 1rem;
}


.readFace {
  position: fixed;
  top: 6.5rem;
  left: 0;
  z-index: 95;
  width: 16rem;
  height: 16rem;
}

.faceQG, .timeTips {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 16rem;
  height: 16rem;
}

.timeTips {
  font-size: 2rem;
  font-weight: bold;
  color: #F2F2F2;
}

#faceVideo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 95;
  transform: rotateY(180deg);
}

#faceCanvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

</style>
